<template>
  <div class="container">
    <div class="row" v-for="i in Math.ceil(regulateurs.length / 4)    " :key="i">
      <div class="col-sm-3" v-for="regulateur in regulateurs.slice((i - 1) * 4, i * 4)" :key="regulateur.id">
        <div class="regulateur">
          <p class="name"><img :src="svg(regulateur.regcouleur)" /><a href="" @click="viewregulateur(regulateur.id)">{{
      regulateur.nom }}</a></p>
          <p class="name">{{ regulateur.typereg }}</p>
          <a href="#" @click="viewregulateur(regulateur.id)"><img :src="image(regulateur.strcode2)" /></a>
          <div v-if="regulateur.id != null">
            <p class="name">{{ regulateur.dtmesure | sdt }}</p>
            <p v-if="regulateur.depart == 0"><span v-if="regulateur.charge != null">Charge : {{ regulateur.charge
                }}/8</span></p>
            <div v-if="regulateur.calibre != null">
              <p>Calibre : {{ regulateur.calibre }}</p>
              <p v-if="regulateur.depart == 0">Continuité : {{ regulateur.continuite }} &ohm;
                (Max={{ calcMaxContinuite(regulateur) }})</p>
              <p>Isolement : {{ regulateur.isolement }} M&ohm; (Min={{ calcMinIsolement(regulateur) }})</p>
              <p class="comment">{{ regulateur.commentaire }}</p>
            </div>
            <p>{{ calcBrillances(regulateur) }}</p>
            <p>
              <span v-if="regulateur.L != 0"> {{ regulateur.L }} Km</span>
              <span v-if="regulateur.NbFeu != 0"> {{ regulateur.NbFeu }} Feux</span>
              <span v-if="regulateur.P != 0"> {{ regulateur.P }} KVA</span>
            </p>
            <p v-if="regulateur.lieu != ''">{{ regulateur.lieu }}</p>
          </div>
        </div>
      </div>
    </div>
    <br /><br /><br />
  </div>
</template>

<script>
import store from "../store";
import { getPrefix, selectApi } from "../lib/api.js";
import { getPath } from "../lib/svg.js";

export default {
  name: "Regulateurs",
  store: store,
  data() {
    return {
      count: 0,
      regulateurs: [],
    };
  },
  filters: {
    sdt: function (dt) {
      if (!dt) return "";
      return (
        dt.substring(8, 10) +
        "/" +
        dt.substring(5, 7) +
        "/" +
        dt.substring(0, 4)
      );
    },
  },
  methods: {
    viewregulateur(id) {
      this.$router.push({ path: 'regulateur/' + id });
    },
    image(code) {
      if (code == undefined || code == "") code = "REG.png";
      let ret = getPrefix() + "/img/" + code;
      return ret;
    },
    doc(code) {
      let ret = getPrefix() + "/doc/regulateurs/" + code;
      return ret;
    },
    svg(code) {
      let path = getPath(code, 0, false);
      return path;
    },
    calcMinIsolement(regulateur) {
      let cal = regulateur.calibre
      let im = "Donneés absentes"
      if ((regulateur.L != 0) && (regulateur.NbFeu != 0)) im = Math.round(cal / ((regulateur.L * 10) + regulateur.NbFeu * 2), 2)
      return im
    },
    calcMaxContinuite(regulateur) {
      let cal = regulateur.calibre
      let cn = "Donneés absentes"
      if ((regulateur.L != 0) && (regulateur.NbFeu != 0)) cn = Math.round(((0.018) * ((regulateur.L * 1000) / 6) + (0.1212 * regulateur.NbFeu)) * 1.2, 2)
      return cn
    },
    calcBrillances(regulateur) {
      if (regulateur.NbBrillance < 1) return ""
      let bri = ""
      if (regulateur.NbBrillance >= 1) bri = regulateur.brillance1
      if (regulateur.NbBrillance >= 2) bri += "," + regulateur.brillance2
      if (regulateur.NbBrillance >= 3) bri += "," + regulateur.brillance3
      if (regulateur.NbBrillance >= 4) bri += "," + regulateur.brillance4
      if (regulateur.NbBrillance >= 5) bri += "," + regulateur.brillance5
      return "Brillance" + (regulateur.NbBrillance == 1 ? "" : "s") + " : " + bri
    },
    refresh() {
      let id = store.state.piste;
      let sql =
        "SELECT r.id,r.nom,r.NbFeu,r.NbBrillance,m.libelle as typereg,m.strcode2,mr.id as idlux,LEFT(mr.Dt,10) as dtmesure,mr.commentaire,mr.charge," +
        "mr.calibre,mr.continuite,mr.isolement,m.strcode3,r.couleur as regcouleur,r.depart,r.L,r.NbFeu,r.NbBrillance,r.brillance1,r.brillance2,r.brillance3,r.brillance4,r.brillance5," +
        "r.NbFeu,r.P,r.lieu" +
        " FROM regulateur AS r" +
        " LEFT JOIN ###.tableref AS m ON m.id=r.idtype" +
        " LEFT JOIN mesureregulateur AS mr ON mr.idregulateur=r.id" +
        " WHERE r.idpiste=" +
        id +
        " AND (mr.Dt=(SELECT MAX(Dt) FROM mesureregulateur WHERE idregulateur=r.id) OR mr.Dt IS NULL) ORDER BY r.lieu ASC,r.nom ASC";
      selectApi(this, sql).then(
        (response) => {
          this.count = response.data.count;
          this.regulateurs = response.data.result;
        },
        (response) => {
        }
      );
    },
  },
  mounted() {
    if (store.state.typeUser == 0) {
      this.$router.push({ name: "login" });
    }
    this.refresh();
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/design.scss";

.regulateur {
  background-color: lightcyan;
  border: 1px solid darkcyan;
  text-align: center;
  padding: 0px;
  margin: 10px 0 0 0;
}

.name {
  font-weight: bold;
}

.comment {
  color: darkblue !important;
  font-weight: bold;
}
</style>